import React, { ReactNode, useRef, useState, useEffect } from 'react'
import { useSize } from 'ahooks'

import Head from 'next/head'
import Nav from './nav'
import Footer from './footer'
// import Login from './loginList'
import './layout.less'
import './privateLayout.less'

interface IPropsModel {
  children?: ReactNode;
  title?: string;
  noFooter?: boolean;
}

function Index ({
  children,
  title = '小电官方网站',
  noFooter = false,
}: IPropsModel): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/typedef
  const wrapperRef = useRef<any>()
  // eslint-disable-next-line @typescript-eslint/typedef
  const scrollRef = useRef<any>()
  // eslint-disable-next-line @typescript-eslint/typedef
  const { height } = useSize(wrapperRef)

  const [wrapperHeight, setWrapperHeight] = useState<number|string>()

  // eslint-disable-next-line @typescript-eslint/typedef
  // const debouncedHeight = useDebounce(wrapperHeight, { wait: 200 })

  useEffect(() => {
    setWrapperHeight(height)
  }, [height])

  return (
    <div className="layout-container">
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="cache-control" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
        <meta httpEquiv="pragma" content="no-cache" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="format-detection" />
        <meta name="baidu-site-verification" content="codeva-uQMQ5XQi7k" />
        <meta
          name="keywords"
          content="小电,充电宝,移动电源,移动充电,手机充电、手机充电宝、充电宝牌子、闪充、快速充电,扫码充电,充电网点,充电宝"
        />
        <meta
          name="description"
          content="小电，国内便利充电创领者，解决手机充电难题。全国海量城市借用充电宝无忧借还，微信支付宝信用免押金，简单快捷，让用户精彩生活永不断电！"
        />
        <meta
          name="baidu_union_verify"
          content="108cd57012fa4df8a1ebb9f98bd0cd68"
        />
        <title>{title}</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="bookmark" href="/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href="//www.dian.so/" />
        <link rel="alternate" hrefLang="zh" href="//www.dian.so/" />
        <link rel="alternate" hrefLang="zh-HK" href="//www.xpowerplus.net/" />
        <link rel="alternate" hrefLang="en-GB" href="//www.chargebolts.com/" />
      </Head>

      <Nav />
      <div className="scroll-content" ref={scrollRef}>
        <div className="content" style={{ height: wrapperHeight }} />
        <div className="wrapper" id="wrapper" ref={wrapperRef}>
          {/* <Login /> */}
          {children}
          {!noFooter && <Footer />}
        </div>
      </div>

      {title === '小电官方网站' && <div className="fixed-box">
        <img onClick={(): void => { window.location.href = '/os/agentRecruit' }} src="//img3.dian.so/lhc/2021/11/10/190w_166h_BDFB31636526684.jpg" />
        <img onClick={(): void => { window.location.href = '/os/cooperation' }} style={{ marginTop: 14 }} src="//img3.dian.so/lhc/2021/11/10/190w_166h_884ED1636527626.jpg" />
      </div>}

      <img
        onClick={(): void => {
          console.log('click img')
          scrollRef.current.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
        }} className="back-top" src="//img3.dian.so/lhc/2021/11/22/92w_92h_4841E1637567121.png"
      />
    </div>
  )
}

export default Index
